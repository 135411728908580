<!--
健康设备
-->
<template>
  <div class="mainBox mainBoxBg">
    <!--健康设备-->
    <!-- ui设计搞改动 -->
    <div class="shipMain shipMainNoPad shipMain2" v-if="false">
      <div class="healthTitle">
        <div class="nameTitle titleMagin">
          <div>温先生</div>
          <img
            class="sexIcon"
            src="@/assets/img/serviceManagement/man.png"
            alt=""
          />
          <div>76岁</div>
        </div>
        <div class="nameTitle">
          <img
            class="sexIcon"
            src="@/assets/img/serviceManagement/phone.png"
            alt=""
          />
          <div>13656565656</div>
          <img
            class="sexIcon"
            src="@/assets/img/serviceManagement/user.png"
            alt=""
          />
          <div>423156196302230000</div>
        </div>
      </div>

      <div class="healthInfo">
        <div class="h_infoList">
          <img
            class="infoListIcon"
            src="@/assets/img/serviceManagement/healthIcon1.png"
            alt=""
          />
          <div class="info_text">
            <div>172</div>
            <div>身高CM</div>
          </div>
        </div>

        <div class="h_infoList">
          <img
            class="infoListIcon"
            src="@/assets/img/serviceManagement/healthIcon2.png"
            alt=""
          />
          <div class="info_text">
            <div>72</div>
            <div>体重KG</div>
          </div>
        </div>
        <div class="h_infoList">
          <img
            class="infoListIcon"
            src="@/assets/img/serviceManagement/healthIcon3.png"
            alt=""
          />
          <div class="info_text">
            <div>20</div>
            <div>BMI</div>
          </div>
        </div>
      </div>

      <!--主图部分-->
      <div class="imgMainBox">
        <img
          class="healthDecive"
          src="@/assets/img/serviceManagement/healthdecive.png"
          alt=""
        />
        <!-- 箭头部分 -->
        <img
          class="jiantou3"
          src="@/assets/img/serviceManagement/jiantou3.png"
          alt=""
        />
        <img
          class="jiantou1"
          src="@/assets/img/serviceManagement/jiantou1.png"
          alt=""
        />
        <img
          class="jiantou2"
          src="@/assets/img/serviceManagement/jiantou2.png"
          alt=""
        />
        <img
          class="jiantou4"
          src="@/assets/img/serviceManagement/jiantou4.png"
          alt=""
        />
        <img
          class="jiantou5"
          src="@/assets/img/serviceManagement/jiantou5.png"
          alt=""
        />
        <!-- 数值定位部分 -->
        <div class="numImgBox">
          <img
            class="de_numImg"
            src="@/assets/img/serviceManagement/numImg2.png"
            alt=""
          />
          <div class="numTxt">
            <span class="numSpan1">36.6</span>
            <span class="numSpan2">℃</span>
          </div>
          <div>
            <span class="numSpan3">体温</span>
          </div>
        </div>

        <div class="numImgBox2">
          <img
            class="de_numImg"
            src="@/assets/img/serviceManagement/numImg1.png"
            alt=""
          />
          <div class="numTxt">
            <span class="numSpan1">110/76</span>
            <span class="numSpan2">mmHg</span>
          </div>
          <div>
            <span class="numSpan3">血压</span>
          </div>
        </div>

        <div class="numImgBox3">
          <img
            class="de_numImg"
            src="@/assets/img/serviceManagement/numImg3.png"
            alt=""
          />
          <div class="numTxt">
            <span class="numSpan1">110/76</span>
            <span class="numSpan2">mmHg</span>
          </div>
          <div>
            <span class="numSpan3">血压</span>
          </div>
        </div>

        <div class="numImgBox4">
          <img
            class="de_numImg"
            src="@/assets/img/serviceManagement/numImg4.png"
            alt=""
          />
          <div class="numTxt">
            <span class="numSpan1">72次/分</span>
          </div>
          <div>
            <span class="numSpan3">心率</span>
          </div>
        </div>
        <div class="numImgBox5">
          <img
            class="de_numImg"
            src="@/assets/img/serviceManagement/numImg5.png"
            alt=""
          />
          <div class="numTxt">
            <span class="numSpan1">99%</span>
          </div>
          <div>
            <span class="numSpan3">血氧</span>
          </div>
        </div>
        <div class="numImgBox6 numImgBox7">
          <img
            class="de_numImg"
            src="@/assets/img/serviceManagement/numImg6.png"
            alt=""
          />
          <div class="numTxt">
            <span class="numSpan1"> 5.12</span>
            <span class="numSpan2">mmol/L</span>
          </div>
          <div>
            <span class="numSpan3">总胆固醇</span>
          </div>
        </div>

        <div class="numImgBox6 numImgBox8">
          <img
            class="de_numImg"
            src="@/assets/img/serviceManagement/numImg6.png"
            alt=""
          />
          <div class="numTxt">
            <span class="numSpan1"> 1.12</span>
            <span class="numSpan2">mmol/L</span>
          </div>
          <div>
            <span class="numSpan3">甘油三酯</span>
          </div>
        </div>

        <div class="numImgBox6 numImgBox9">
          <img
            class="de_numImg"
            src="@/assets/img/serviceManagement/numImg6.png"
            alt=""
          />
          <div class="numTxt">
            <span class="numSpan1">99%</span>
            <span class="numSpan2">mmol/L</span>
          </div>
          <div>
            <span class="numSpan3">HDL-C</span>
          </div>
        </div>
        <div class="numImgBox6 numImgBox10">
          <img
            class="de_numImg"
            src="@/assets/img/serviceManagement/numImg6.png"
            alt=""
          />
          <div class="numTxt">
            <span class="numSpan1">99%</span>
            <span class="numSpan2">mmol/L</span>
          </div>
          <div>
            <span class="numSpan3">LDL-C</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 图片替换 -->
    <div class="shipMain shipMainNoPad shipMain3">
      <img
        src="@/assets/img/serviceManagement/serBg.png"
        alt=""
        class="serBg"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timeData: "",
      changeIndex: 1,
    };
  },
  created() {},

  mounted: function () {},
  destroyed: function () {},
  computed: {},
  watch: {},
};
</script>
<style lang="less" scoped>
.serBg {
  width: 100%;
  height: 640px;
}
.shipMain2 {
}
.shipMain3 {
  height: 710px !important;
  padding: 70px 110px !important;
}
.mainBoxBg {
  background: linear-gradient(180deg, #55b5eb 0%, #55d9d7 100%);
}
.healthTitle {
  width: 600px;
  margin: 0 auto;
  background: url("../../assets/img/serviceManagement/healthBg.png") no-repeat;
  background-size: cover;
  height: 70px;
  overflow: hidden;
}
.nameTitle {
  display: flex;
  justify-content: center;
  align-content: center;
  div {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    margin-right: 20px;
  }
  .sexIcon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
}
.titleMagin {
  margin: 7px 0;
}
.healthInfo {
  min-width: 300px;
  margin: 22px auto 32px;
  display: flex;
  justify-content: center;
  align-content: center;
  .h_infoList {
    display: flex;
    margin-right: 65px;
    align-items: center;
  }
  .infoListIcon {
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }
  .info_text {
    font-size: 16px;
    font-weight: 400;
    color: #49acea;
    text-align: center;
  }
}
.imgMainBox {
  position: relative;
  width: 528px;
  margin: 0 auto;
}

.healthDecive {
  width: 528px;
  height: 528px;
}
.jiantou3 {
  width: 187px;
  height: 116px;
  left: -29px;
  top: 39px;
  position: absolute;
}
.jiantou1 {
  width: 178px;
  height: 35px;
  left: -38px;
  top: 221px;
  position: absolute;
}
.jiantou2 {
  width: 193px;
  height: 92px;
  left: -49px;
  top: 383px;
  position: absolute;
}
.jiantou4 {
  width: 143px;
  height: 115px;
  right: -18px;
  top: 55px;
  position: absolute;
}
.jiantou5 {
  width: 140px;
  height: 57px;
  right: -71px;
  top: 348px;
  position: absolute;
}
.numImgBox {
  text-align: center;
  position: absolute;
  left: -110px;
  top: 15px;
  span {
    color: #fea104;
  }
}
.numImgBox2 {
  text-align: center;
  position: absolute;
  left: -137px;
  top: 192px;
  span {
    color: #49acea;
  }
}
.numImgBox3 {
  text-align: center;
  position: absolute;
  left: -149px;
  top: 386px;
  span {
    color: #03ad82;
  }
}
.numImgBox4 {
  text-align: center;
  position: absolute;
  right: -102px;
  top: 8px;
  span {
    color: #fb4d4d;
  }
}

.numImgBox5 {
  text-align: center;
  position: absolute;
  right: -251px;
  top: 9px;
  span {
    color: #fe7e1d;
  }
}
.numImgBox6 {
  text-align: center;
  position: absolute;

  span {
    color: #d82727;
  }
}

.numImgBox7 {
  right: -162px;
  top: 196px;
}
.numImgBox8 {
  right: -303px;
  top: 174px;
}

.numImgBox9 {
  right: -186px;
  top: 383px;
}
.numImgBox10 {
  right: -334px;
  top: 348px;
}
.de_numImg {
  width: 72px;
  height: 72px;
}
.numSpan1 {
  font-size: 20px;
  font-weight: 400;
}
.numSpan2 {
  font-size: 12px;
  font-weight: 400;
}
.numTxt {
}
.numSpan3 {
  font-size: 16px;
  font-weight: 400;
}
</style>
